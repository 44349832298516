import _ from 'lodash'
import { Collection } from './collection'
import { Filters } from './local-adapter/filters'
import { DEFAULT_QUERY_METADATA } from './store'

// Finds entities using ElasticSearch.
// You must supply the ElasticSearch APIs, which differ between the web and mobile apps.
// Example usage:
//
// const collection = new Query(apis)
//   .setEntityType('/1.0/entities/metadata/load.json')
//   .getCollection()
//
// collection.find()
//  .then((results) => {
//    results.forEach((result) => {
//      console.log('Got load', result.load)
//    })
//  })
// EQL query
// /1.0/entities/query

export const ALL = "_all"

export class Query {
  api: any
  entityMetadata: any
  entityFilter: any
  computations: any
  filters: any
  groups: any
  orders: any
  query: any
  queryPath: string
  queryType: string
  queryPaths: (string | {path: string; boost: number})[]
  highlightingEnabled: boolean
  metadata: any
  debug: any
  shouldStripMetadata: boolean

  constructor(api) {
    this.api = api
    this.entityMetadata = undefined
    this.entityFilter = []
    this.computations = {}
    this.filters = []
    this.groups = []
    this.orders = []

    this.query = undefined
    this.highlightingEnabled = false
    this.metadata = {
      ...DEFAULT_QUERY_METADATA,
    }
    this.debug = {}
    this.shouldStripMetadata = true
  }

  public getLocalFilters() {
    // TODO(Peter): we should cache the result base on query
    const localFilters = new Filters()
    const filterSpecs = this.build().filters
    const filterFunction = localFilters.buildFilterFunction(filterSpecs)
    return { filter: filterFunction }
  }

  public addEntityType(entityType) {
    const metadata = this.getUniqueIdFromEntityType(entityType)
    if (_.isEmpty(this.entityFilter)) {
      throw new Error('Cannot call addEntityType before setEntityType')
    }
    const entityFilter: any = _.first(this.entityFilter)
    entityFilter.values.push({ entityId: metadata.uniqueId })
    return this
  }

  public setEntityType(entityType) {
    const metadata = this.getUniqueIdFromEntityType(entityType)
    this.entityMetadata = metadata
    this.entityFilter = [
      {
        type: 'containsEdge',
        path: 'mixins.active',
        values: [{ entityId: metadata.uniqueId }],
      },
    ]
    return this
  }

  public setComputations(computations = {}) {
    this.computations = computations
    return this
  }

  public setFilters(filters: any[] = []) {
    this.filters = filters
    return this
  }

  public setGroups(groups: any[] = []) {
    this.groups = groups
    return this
  }

  public setOrders(orders: any[] = []) {
    this.orders = orders
    return this
  }

  public setQuery(query) {
    this.query = query
    return this
  }

  public setQueryPath(queryPath) {
    this.queryPath = queryPath
    return this
  }

  public setQueryPaths(queryPaths) {
    this.queryPaths = queryPaths
    return this
  }

  public setQueryType(queryType) {
    this.queryType = queryType
    return this
  }

  public setMaxSizePerGroup(maxSizePerGroup) {
    this.metadata.maxSizePerGroup = maxSizePerGroup
    return this
  }

  public setHighlightingEnabled(enabled) {
    this.highlightingEnabled = enabled
    return this
  }

  public setSize(size) {
    this.metadata.size = size
    return this
  }

  public setOffset(offset) {
    this.metadata.offset = offset
    return this
  }

  public setMetadata(metadata) {
    _.forEach(metadata, (value, key) => {
      this.metadata[key] = value
    })
    return this
  }

  public setDebug(debug) {
    _.forEach(debug, (value, key) => {
      this.debug[key] = value
    })
    return this
  }

  public setShouldIncludeLeafEntities(shouldIncludeLeafEntities) {
    this.metadata.shouldIncludeLeafEntities = shouldIncludeLeafEntities
    return this
  }

  public setShouldStripMetadata(shouldStripMetadata) {
    this.shouldStripMetadata = shouldStripMetadata
    return this
  }

  get shouldIncludeLeafEntities() {
    return this.metadata.shouldIncludeLeafEntities
  }

  public build() {
    const metadata = _.cloneDeep(this.metadata)
    const debug = _.cloneDeep(this.debug)

    const filters = this.entityFilter.concat(this.filters)

    // convert the "contains" filter from 'or' to 'and' filter
    // if there is presented of the flag "conditionalOp: and"
    const normalizedFilters = this.normalizeContainsFilter(filters)
    const cleanedFilters = this.filterOutFiltersMissingValues(normalizedFilters)
    let cleanedOrders = this.orders;
    if (!_.isEmpty(this.query)) {
      if (this.queryPaths && this.queryPaths.length > 0) {
        // Optional support for providing boost values for each path
        const orPaths = this.queryPaths.map((p) => { 
          if (typeof p === 'string') {
            return { path: p, boost: 1 };
          } else {
            return { path: p.path, boost: p.boost ?? 1 };
          }
        });
        // Remove orders if boost values are present, ordering provided by ES
        if (_.some(this.queryPaths, (p) => _.has(p, 'boost'))) { 
          cleanedOrders = [];
        }
        cleanedFilters.push({
          type: 'multipleMatch',
          orPaths: orPaths,
          value: this.query
        });
      } else {
        cleanedFilters.push({
          path: this.queryPath || '_all',
          type: this.queryType || 'query',
          value: this.query,
        })
      }
    }
    return {
      computations: this.computations,
      filters: cleanedFilters,
      groups: this.groups,
      orders: cleanedOrders,
      metadata: metadata,
      debug: debug,
    }
  }

  public clone() {
    const query = new Query(this.api)
    query.entityFilter = _.cloneDeep(this.entityFilter)
    query.computations = _.cloneDeep(this.computations)
    query.filters = _.cloneDeep(this.filters)
    query.groups = _.cloneDeep(this.groups)
    query.metadata = _.cloneDeep(this.metadata)
    query.orders = _.cloneDeep(this.orders)
    query.highlightingEnabled = this.highlightingEnabled
    query.shouldStripMetadata = this.shouldStripMetadata
    return query
  }

  public getCollection() {
    return new Collection(this.api, this)
  }

  private getUniqueIdFromEntityType(entityType) {
    const store = this.api.getStore()
    const metadata = store.getRecord(entityType)
    if (!metadata) {
      throw new Error(`Cannot find metadata with uri=${entityType}`)
    }
    return metadata
  }

  // This is a hack to prevent filters with invalid values from breaking views via bad API requests
  private filterOutFiltersMissingValues(filters) {
    return filters.filter((f) => {
      return f.type !== 'match' || !_.isUndefined(f.value) || !_.isUndefined(f.values)
    })
  }

  /**
   * Normalize filters
   * 1. Convert "contains" with "conditionalOp: 'and'" to "AND" filter
   */
  private normalizeContainsFilter(filters) {
    const containsTypeFilters = _.filter(filters, { conditionalOp: 'and' })
    const nonContainsTypeFilters = _.pullAll(filters, containsTypeFilters)

    const normalizedFilters = _.reduce(
      containsTypeFilters,
      (acc, filter) => {
        const values = _.get(filter, 'values')

        if (_.isEmpty(values)) {
          return acc.concat(this.omitNonStandardFields(filter))
        }

        /**
         * TODO: we should add support to do a AND operation in the "contain"
         * query.  For now, split up the "values" into individual filter
         * to simulate the "and" operation
         */
        const filterTemplate = this.omitNonStandardFields(_.omit(filter, 'values'))
        const convertedFilters = _.reduce(
          values,
          (expandedFilter, value) => {
            const andFilter = _.clone(filterTemplate)
            _.set(andFilter, 'value', value)
            return expandedFilter.concat(andFilter)
          },
          []
        )

        return acc.concat(convertedFilters)
      },
      []
    )

    return [...nonContainsTypeFilters, ...normalizedFilters]
  }

  // strip out non standard fields
  private omitNonStandardFields(filter) {
    return _.omit(filter, ['conditionalOp'])
  }
}
